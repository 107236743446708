//  微信
import config from '@/common/config'
import axios from '@/common/axios'
import urls from '@/common/urls'
// import tool from '@/common/tool'
import store from '@/store'
import wx from 'weixin-js-sdk'
const initWx = () =>{
    if(store.state.common.app_id) return;
    const url = `${urls.jssdk}?url=${encodeURIComponent(window.location.href.split('#')[0])}`;
    axios.get(url).then(res=>{
        const data = res.data;
        store.dispatch('setItem',{app_id:data.app_id})
        wx.config({
            debug:false,
            appId:data.app_id,
            timestamp:data.timestamp,
            nonceStr: data.noncestr,
            signature: data.signature,
            jsApiList:config.wxinterface
        })
    }).catch(err=>{
        console.log("微信SDK校验失败")
    })
}
wx.ready(()=>{
    // initShare();
    // initMenu();
})
const shareMessage = (info) => {
    let url = info.url || window.location.href;
    let logo = config.logo
    wx.updateAppMessageShareData({ 
        title: info.title, // 分享标题
        desc: info.description, // 分享描述
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: logo, // 分享图标
        success: function () {
            // shareAddscrore()
            // 设置成功
        }
    })
    wx.updateTimelineShareData({ 
        title:  info.title, // 分享标题
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: logo, // 分享图标
        success: function () {
            // shareAddscrore()
            // 设置成功
        }
    })

}
const initMenu = () => {
    wx.hideAllNonBaseMenuItem();
    wx.showMenuItems({
        menuList: [
            'menuItem:share:appMessage',
            'menuItem:share:timeline',
            'menuItem:favorite',
        ] // 要显示的菜单项，所有menu项见附录3
    });
}
export default {
    init:initWx,
    shareMessage,
    initMenu,
    wx
}