// api模块
import config from "@/common/config"
import tool from "@/common/tool"

const urls ={
  openid:'/ui/api/openid',
  jssign:'​/ui/api/jssign', // sisign
  uploads:{
    file:'/ui/api/uploaddata',
    base64:'/ui/api/uploadbase64',
    video:"/api/upload_file_voice",
  },
  meetingStatus:"/background/meeting/status",
  api:{
      pdf:'/api/manual_book', // 医生是 1 管理员 2
      getmeet:'/background/api/meet',
      screenhost:'/background/api/img',
      videoscreen:'/background/api/video',
      meetlist:'/background/api/nopic'
  },
  admin:{
      code:'/ui/admins/sms', // 验证码
      login:'/ui/admins/login', // 登录
      detail:'/ui/admins/detail', // 管理员详细信息
      list:'/ui/admins/doctor_list', // 管理员医生列表
      doctorlist:'/ui/admins/doctor_meeting_list', // 医生信息
      doctor:'/ui/admins/doctor_info', // 医生信息
      courseware:'/ui/admins/class', // 课件列表
      meeting:'/ui/admins/meeting', // 会议创建
      sign:'/ui/sign', // 签署协议
      bindCourseware:"/ui/doctor/meet_bind_class", //医生会议绑定课件
      cancers:"/ui/api/cancer",// "获取癌肿" 
      haveCourseware:"/ui/doctor/use_class",// "当前医生使用过的课件"
      job:"/ui/api/job", // 检查职称
  },
  doctor:{
      info:'/ui/doctor/info',  // 医生信息
      hospital:'/ui/doctor/hospital', // 医院列表
      department:'/ui/doctor/department', // 科室列表
      meeting:'/ui/doctor/meeting', // 会议列表
      meetList:'/background/meeting', //观众页面会议列表
      questionnairedetail:'/ui/doctor/questionnairedetail', // 问卷列表
      questionnaireanswer:'/ui/doctor/questionnaireanswer', // 问卷答案
      sign:'/api/doctor_sign',
      sfz:'/ui/admins/sfz',
      register:"/ui/doctor/unreg"
  },
  client:{
      sign:'/api/sign'
  },
  wxarticle:{
      list:'/api/media_list'
  },
}

let host = tool.isLocal() ? config.localhost : config.host;
for(let key in urls){
    if(typeof urls[key] == "object"){
        for(let i in urls[key]){
            urls[key][i] =   `${host}${urls[key][i]}`;
        }
        continue
    }
    urls[key] = `${host}${urls[key]}`;
}
export default urls;