<template>
  <router-view  />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
  /* background:#ECECEC; */
  background:#fff;
  font-weight: 400;
  font-size:4vw;
  overflow: hidden;
}
</style>
