import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import '@/assets/css/reset.css'
import  ElementPlus from 'element-plus';
import { LocaleInjectionKey,localeProviderMaker }  from 'element-plus';
// import 'element-plus/lib/theme-chalk/index.css';
import { VueCropper }  from "vue-cropper";
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/assets/css/theme/index.scss'
import '@/assets/css/index.css'
import tips from  '@/common/tips'
import axios from '@/common/axios'
import tools from '@/common/tool'
import urls from '@/common/urls'
import config from '@/common/config'
import vConsole from '@/common/vconsole'
import Video from 'video.js'
import 'video.js/dist/video-js.css'

let Vue = createApp(App)

if (tools.isLocal() || tools.isDevelopment()) {
  // Vue.use(vConsole)
}
Vue.use(router)
Vue.use(store)
Vue.use(ElementPlus)

const opts = {locale:zhCn};
const localeProvides = localeProviderMaker(opts.locale);
Vue.provide(LocaleInjectionKey, localeProvides);

Vue.config.globalProperties.$video = Video
Vue.config.globalProperties.$tips = tips
Vue.config.globalProperties.$axios = axios
Vue.config.globalProperties.$config = config
Vue.config.globalProperties.$urls = urls
Vue.config.globalProperties.$tools = tools

Vue.mount('#app')
