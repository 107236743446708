export default {
    tips:{
      errCode:{
        400:"参数错误",
        403:"权限受限，请联系管理员",
        404:"请求链接不存在,请联系管理员",
        405:"请求方式不正确,应为POST/GET",
        500:"服务器错误,请联系管理员",
        default:"无效的请求,状态码($),请联系管理员",
        other:"未知错误，请联系管理排查"
      }
    },
    // branch:"dev",
    localhost:"kapi",
    host:"https://bzjt.imedunion.com",
    logo:"https://bzjt.imedunion.com/image/logo.png",
    websocketHost:"wss://bzjt.imedunion.com",
    types:{
      exports:{
        zip:'application/zip',
        xlsx:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
      },
      pays:{
        0:'未付款',
        10:'会议审核中 ',
        20:'会议资料整理中',
        25:"付款不成功",
        30:'基金会审核中',
        35:'付款失败更新返回基金会审核中',
        40:'付款成功',
      }
    },
    auth:[0,1], // 观众,管理员
    maxPlayTimes:30, // 最大播放时间
    spaceTime:7, // 提示间隔时间
    popups:{
      infloor:'进入课堂',
      outfloor:'离开课堂',
      academic:'教授',
      video:['课程即将开始','课程即将开始，专家准备中','课程即将开始，请大家认真学习'],
      endVideo:'课程已经开始，请点击视频区播放按钮',
      startVideo:'课程即将开始，请大家稍候片刻',
      startMeet:'会议正式开始，请主持专家致开场词',
      operaBtn:[
        { 
          content:['是否确认进入正式会议流程？'],
          notice:'注意：点击“是”后会议不可重新开始',
          btn:['否','是']
        },
        { 
          content:['是否确认进入“课程”流程？'],
          notice:'注意：点击“是”后不可退回',
          btn:['否','是']
        },
        { 
          content:['是否确认进入“讨论”流程？'],
          notice:'注意：点击“是”后不可退回',
          btn:['否','是']
        },
        { 
          content:['是否确认会议结束？'],
          notice:'注意：点击“是”后不可退回',
          btn:['否','是']
        }
      ],
      operaOther:{
        courseNotEnd:{ 
          content:['课程还未播放完，请等待课程播放完成后再进行讨论环节'],
          // notice:'',
          btn:['是']
        },
        courseHaveMoreComment:{ 
          content:['温馨提醒'],
          notice:'线上观众可能还有更多问题。',
          btn:['是']
        },
        courseHaveMoreComment_end:{ 
          content:['温馨提醒','还有观众想向您请教，去看看'],
          notice:'有什么问题吧！',
          btn:['是']
        },
        coursenotintime:{ 
          content:['会议开始时间不可以在22-7点之间'],
          btn:['是']
        },
        delUserQuestion:{ 
          content:['此操作将删除此自定义问题, 是否继续?'],
          // notice:'',
          btn:['是','否']
        }
      },
      meetStatus:{
        content:['请选择会议类型','(会前30分钟可点击正式开会)'],
        btn:['测试会议','正式会议']
      }
    },
    search:{
      pagesize:10,
      page:1
    },
    wxinterface:[
      // "startRecord",
      // "stopRecord",
      // "onVoiceRecordEnd",
      // "playVoice",
      // "pauseVoice",
      // "stopVoice",
      // "onVoicePlayEnd",
      // "uploadVoice",
      // "downloadVoice",
      "closeWindow",
      "scanQRCode",
      "updateAppMessageShareData",
      "onMenuShareAppMessage",
      "onMenuShareTimeline",
      "updateTimelineShareData",
  ]

}